import { objectEntries } from "~/utils/object-entries"

export const customVariablesNames = {
  scrollBar: "--scroll-bar",
  scrollBarGrid: "--scroll-bar-grid",
  stableVh: "--stable-vh",
  vh: "--vh",
  vh100: "--vh100",
} satisfies Record<string, `--${string}`>

export const customVariables = objectEntries(customVariablesNames).reduce((acc, [key, value]) => {
  return { ...acc, [key]: `var(${value})` }
}, {} as { [key in keyof typeof customVariablesNames]: `var(${(typeof customVariablesNames)[key]})` })
